import React from 'react';
import { Row, Col } from 'antd';
import AboutTile from '../../AbouTile';
import { stripTags, domHtml } from '../../../utils/stripTags';

import SEO from '../../Seo';

const pageText = {
  paraOne: `I'm a Software Engineer - I've been building CI/CD models and breaking monoliths into distributed services at VMware since August 2021. I graduated from NCSU a few months prior, where I studied Computer Science.`,

  paraTwo: `At VMWare I have taken on many DevOps related responsibilities, working to create seamless ci/cd systems for numerous AWS-based services. The infrastructure I designed works all the way from a code change (NodeJS), to building to new image (Docker), to testing that new image, 
  to deploying that image to Kubernetes clusters (AWS EKS), to providing the service with the AWS resources it needs (Terraform), and even handling the observability into the newly deployed service. I have enjoyed DevOps and design at the architectural level 
  for the creative challenges but also its service-agnostic nature. 

    Before VMware I interned with Cisco and Nokia, where I got great fullstack and QA experience. At <a href="https://techatncsu.github.io/"><b>Tech@NCSU</b></a> 
    I served as Vice President and contributed to our fullstack development for local non-profits. My favorite sideproject of mine is the intelligent playlist maker, <a href="/blog/tunechef"><b>TuneChef</b></a>, which sits on a React, NodeJS, MongoDB stack.
    Actually Spotify later made their own version of this in the form of Spotify Blends.`,

  paraThree: `Coding is fun but it\'s not all I do. I'm involved in the San Francisco art community and hosted an art show in October 2023 with 300 attendees, you can checkout the website I made for it at <a href="www.335shotwell.net"><b>335shotwell.net</b></a>.
  I love <a href="/blog/art"><b>drawing</b></a> with ink or charcoal, meticulously crafting 
    Spotify playlists,  diving into the discographies of obscure artists, and have recently started making my own music.`
};

const AboutMe = () => {
  const description = `${pageText.paraOne} ${stripTags(pageText.paraTwo)}`;
  return (
    <>
      <div>
        <SEO
          title="About"
          description={description}
          path=""
          keywords={['Bryce', 'Junkins', 'backend developer', 'Javascript', 'ReactJS', 'NodeJS', 'devops']}
        />
        <h1 className="titleSeparate"> I'm Bryce 🙋🏻‍♂️</h1>
        <p>
          {pageText.paraOne}
        </p>
        <p dangerouslySetInnerHTML={domHtml(pageText.paraTwo)} />
        <p dangerouslySetInnerHTML={domHtml(pageText.paraThree)} />
      </div>
      <Row gutter={[20, 20]}>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="location.png"
            height={60}
            alt="location image"
            textH4="Currently In"
            textH3="San Francisco"
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="graduation.png"
            alt="graduation image"
            textH4="Studied"
            textH3="Computer Science"
            height={60}
            width={60}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="calendar.png"
            alt="web image"
            textH4="Coordinator"
            textH3="Hosting Art Related Events"
            height={60}
            width={60}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="headphones.png"
            alt="headphones image"
            textH4="Music"
            textH3="Discovering New Music"
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="pencil.png"
            alt="pencil image"
            textH4="Drawing"
            textH3="Sketching & Doodling"
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="volleyball.png"
            alt="volleyball image"
            textH4="Volleyball"
            textH3="Casual Usually"
          />
        </Col>
        
      </Row>
    </>
  );
};
export default AboutMe;
